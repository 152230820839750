import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: {
  page: string;
  mainPageToken?: string;
  leftText?: string;
  rightText?: any;
}) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;


  return (<>
    <div className={"navbar"}>
      <div className={"navbar__left"}>
        <div className={"navbar__logo"}>Black-belt
        </div>
      </div>
      
      <div className={"navbar__center"}>
        {props.leftText &&
        <div className={"hide-on-smartphones space--small navbar__position"}>Pour le poste de {props.leftText}</div>}
        {props.rightText && <div className={"hide-on-smartphones"}>
          {props.rightText}
        </div>}
      </div>
      
      <div className={"navbar__right"}>

        {/*<button className={"navbar__btn2"} onClick={()=>{*/}
        {/*  appContext.dispatchPanels({*/}
        {/*    type: "OPEN_WINDOW",*/}
        {/*    value: {*/}
        {/*      type: "businessBlackBeltOnboarding",*/}
        {/*      data: {token:props.mainPageToken}*/}
        {/*    }*/}
        {/*  });*/}
        {/*}}> Onboarding </button>*/}

        <Link to={`/onboarding/${props.mainPageToken}`}>
          <button className={"navbar__btn2"}>Onboarding</button>
        </Link>

          {/*<button className={"navbar__btn2"} onClick={()=>{*/}
          {/*  appContext.dispatchPanels({*/}
          {/*    type: "OPEN_WINDOW",*/}
          {/*    value: {*/}
          {/*      type: "businessBlackBeltStats",*/}
          {/*      data: {token:props.mainPageToken}*/}
          {/*    }*/}
          {/*  });*/}
          {/*}}> Statistiques </button>*/}

        {//appContext.appNavigation.page === "ClientPage" &&
        <button className={"navbar__btn2"} onClick={()=>{
          appContext.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
              type: "businessBlackBeltManagers",
              data: {token:props.mainPageToken}
            }
          });
        }}> Équipe </button>}
        
        <Link to={`/entreprise/${props.mainPageToken}`}>
          <button className={"navbar__btn2"}>Tous les postes</button>
        </Link>
      </div>
    </div>
  
  </>);
}