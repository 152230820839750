import React, {useContext, useEffect, useReducer, useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";
import {format} from "date-fns";
import axios from "axios";
import dataReducerBlackBeltTchats from "./dataReducerBlackBeltTchats";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props: { candidate: any, business:any}) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;
    const [blackBeltTchat, setBlackBeltTchat] = useState("");
    const [blackBeltTchats, dispathBlackBeltTchats] : [any, Function] = useReducer(dataReducerBlackBeltTchats, []);


    useEffect(() => {
        (async function() {
            try {
               if(!!props.candidate.blackBeltTchat)
               {dispathBlackBeltTchats({
                    type: "INITIALIZE_DATA", value: props.candidate.blackBeltTchat
                });}
               else{
                   {dispathBlackBeltTchats({
                       type: "INITIALIZE_DATA", value: []
                   });}
               }
            } catch(error) {
                devLog(error);
            }
        })();
    }, [props.candidate]);

    return (<div>

        <div className={"candidate-page__tchat-container"}>
          {
            blackBeltTchats?.length === 0 && <div className={"text--center"}>Aucun message</div>
          }
            {(blackBeltTchats ?? []).map((item: any) => (<div className={"candidate-page__tchat-item"}>
                <div className={"candidate-page__tchat-avatar"}>
                    {item.from ==="DHA" && <img className={"candidate-page__tchat-avatar-img"} src={"https://static.myshortlist.co/dha.jpg"}/>}
                    {item.from ==="GMA" && <img className={"candidate-page__tchat-avatar-img"} src={"https://static.myshortlist.co/gma.jpg"}/>}
                    {item.from ==="SPT" && <img className={"candidate-page__tchat-avatar-img"} src={"https://static.myshortlist.co/spt.jpg"}/>}
                    {item.from ==="JAD" && <img className={"candidate-page__tchat-avatar-img"} src={"https://static.myshortlist.co/jad.jpg"}/>}
                    {item.from !== "DHA" && item.from !== "GMA" &&item.from !== "SPT" &&item.from !== "JAD" && <img className={"candidate-page__tchat-avatar-img"} src={"https://static.myshortlist.co/trigram-default.jpg"}/>}
                </div>
                <div>
                    <div
                        className={"candidate-page__tchat-title"}> {item.from} - {item.dateOfCreation ? format(new Date(item.dateOfCreation), "dd/MM/yyyy") : ""}</div>
                    <div>{item.message}</div>
                </div>
            </div>)).reverse()}
        </div>
        <input className={"candidate-page__tchat-input"} placeholder={"Votre message"} value={blackBeltTchat}
               onChange={(e) => {
                   setBlackBeltTchat(e.target.value);
               }}/>

        <button className={"candidate-page__tchat-btn"} disabled={!blackBeltTchat} onClick={async () => {
            const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/tchat`,
                {
                    candidacyId:props.candidate.candidacyId,
                    message:blackBeltTchat,
                    firstName:props.business.businessName,
                    type: "client",
                }
                , {
                    timeout: 5000,
                    withCredentials: true,
                })).data;

            if (status !== "ok") {
                return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            }

            dispathBlackBeltTchats({
                type: "UPDATE_CANDIDACY", value: {
                    dateOfCreation: new Date(),
                    from: props.business.businessName,
                    message: blackBeltTchat
                }
            });
            setBlackBeltTchat("");
            notification(appContext.dispatchPanels, "message envoyee", "success");

        }}>Envoyer
        </button>
    </div>);
}