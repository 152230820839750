import React, {useContext} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import CloseButton from "../components/windows/CloseButton";

interface IWindowLayout {
  windowTitle: string,
  size?: 'big' | 'small' | 'bigger',
  children: any,
  status?: "ok" | "pending" | "error" | "asleep" | "fetching" | "fetched"
}

export default (props: IWindowLayout) => {

  const status: "ok" | "pending" | "error" | "asleep" | "fetching" | "fetched" = props.status ? props.status : "pending";

  if(status === "pending" || status === "asleep" || status === "fetching") {
    return (<WindowLayout {...props}>
      <div> Chargement... </div>
    </WindowLayout>);
  }

  if(status === "error") {
    return (<WindowLayout {...props}>
      Une erreur est survenue
    </WindowLayout>);
  }

  return (
      <WindowLayout {...props}>
        {props.children}
      </WindowLayout>
  );
}

function WindowLayout(props: any) {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  let size = !!props.size ? props.size : 'small';
    return (
        <>
            <div
                className={`window-container window-container--appear ${appContext.appPanels.window.isWindowClosing ? "window-container--disappear" : ""}`}
                onClick={(e) => {
                    e.preventDefault();
                }}>
                <section
                    className={`window window--appear ${appContext.appPanels.window.isWindowClosing ? "window--disappear" : ""} ${size === 'big' ? 'window--big' : ''} ${size === 'bigger' ? 'window--bigger' : ''}`}
                    onClick={(e) => {e.stopPropagation();}}>

                    <article className={"animation-fade-in--faster"}>

                        <article className={"window__header"}>
                            <span className={"window__header__logo"}></span>
                            <CloseButton eventOnClick={() => {
                                appContext.dispatchPanels({type: "CLOSE_WINDOW", value: null});
                            }}/>
                        </article>

                        <article className={"window__title"}>
                            {props.windowTitle}
                        </article>

                        <article className={"window__content"}>
                            {props.children}
                        </article>


                    </article>

                </section>
            </div>
        </>
    );

}




