import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";


export default (props: { candidate: any, }) => {

    return (<>
        <section className={"flash-card-container"}>

            <div className={"candidate-page__informations-container"}>
            <div>
                <h1 className={"candidate-page__title text--green"}>Points forts du profil:</h1>
                {props.candidate?.blackBeltImportant?.map((item:any)=>(<div className={"row"}>
                    <div className={"candidate-page__list-logo"}> </div>
                    <div>{item}</div>
                </div>))}
            </div>
            <Spaces n={0}/>

            <div>
                <h1 className={"candidate-page__title text--green"}>Informations:</h1>
                {props.candidate?.blackBeltInformations?.map((item:any)=>(<div className={"row"}>
                    <div className={"candidate-page__list-logo"}> </div>
                    <div>{item}</div>
                </div>))}
                <div className={"row"}>
                    <div className={"candidate-page__list-logo"}> </div>
                    <div> Disponibilite: {props.candidate.blackBeltAvailability}</div>
                </div>
            </div>
            </div>
        </section>
    </>);
}