import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";


export default (props: any) => {

    return (<>

        <Spaces n={0}/>

        <div className={"column"}>
        {props.keep === "yes" && <div>Vous avez choisi de garder ce candidat</div>}
        {props.keep === "no" &&<div> Vous avez choisi de ne pas garder ce candidat</div>}
    
            <Spaces n={0}/>
            
            <button className={"button--linkedin"} onClick={async() => {
                props.setEvaluationStep(1);
                props.sendEvaluation("");
            }}>
               Changer mon choix
            </button>
        </div>


    </>);
}