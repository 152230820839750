import React from "react";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default (props: { candidate: any, }) => {
  
  return (<>
    <section className={"flash-card-container"}>
      <h1 className={"candidate-page__title text--blue"}>Feedback entretien BlackBelt:</h1>
      {props.candidate?.interview2?.map((item: string) => <div className={"row"}>
        <div className={"candidate-page__list-logo"}> </div>
        <div>{item}</div>
      </div>)}
    </section>
  </>);
}