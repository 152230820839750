
import React from 'react';
import {useContext} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props: any) => {

    const appContextV2: IAppContext = useContext(AppContext) as IAppContext;

    return(<section className={`notification`}>
        <article className={`notification__text ${getColor(appContextV2.appPanels.notifications.notificationType)}`}>
            {appContextV2.appPanels.notifications.notificationText}
        </article>
    </section>);

}

function getColor(type: "error"|"warning"|"information"|"success"|"success-long") {

    switch(type) {

        case 'error':
            return 'notification__text--error';

        case 'warning':
            return 'notification__text--warning';

        case 'success-long':
        case 'success':
            return 'notification__text--success';

        default:
            return 'notification__text--information';
    }

}
