import React, {useContext} from "react";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";

export default (props: {
  candidate: any;
  job: string;
  corporation: string;
  id: string;
}) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  const average = Math.round((((props.candidate?.blackBeltCulture ?? 0) * 20) + ((props.candidate?.blackBeltHardSkills) ?? 0) + ((props.candidate?.blackBeltSoftSkills * 20) ?? 0)) / 3);
  
  return (<>
    <section className={"flash-card-container header"}>
      <div className={"candidate-page__space-between"}>
        
        <div>
          <button className={"button--linkedin"}
                  title={"Partagez le profil avec vos collaborateurs pour recevoir leurs avis sur ce candidat ou cette candidate"}
                  onClick={()=>{
            appContext.dispatchPanels({
              type: "OPEN_WINDOW",
              value: {
                type: "form",
                data: {
                  job: props.job, corporation: props.corporation, id: props.id, action: "share", candidate: props.candidate
                }
              }
            });
          }}>Partager</button>
        </div>
        
        <div className={"header--up"}>
          
          <div className={"space-flex--center"}>
            <div className={"header__avatar-container"}>
              <img className={"header__avatar"} src={props.candidate?.avatarUrl}
                   alt={"avatar"}/>
            </div>
          </div>
          
          <div className={"header__name text--center"}>
            {(props.candidate?.firstName?.toUpperCase() ?? "A")[0]}. <span className={"blur"}>Bertrand</span>
          </div>
          
          <div className={"column"}>
            <div>
                <button className={"btn--hollow header__linkedin"}
                        title={`Accéder au profil LinkedIn de ${props.candidate?.firstName}`}
                        onClick={()=>{
                  appContext.dispatchPanels({
                    type: "OPEN_WINDOW",
                    value: {
                      type: "form",
                      data: {
                        job: props.job, corporation: props.corporation, id: props.id, action: "linkedin", candidate: props.candidate
                      }
                    }
                  });
                }}>
                  <div className={"modern-link"}>
                    Voir le profil Linkedin
                  </div>
                </button>
            </div>
            
            <div>
              <button className={"btn--hollow header__linkedin"}
                      title={`Voir le CV de ${props.candidate?.firstName}`}
                      onClick={()=>{
                appContext.dispatchPanels({
                  type: "OPEN_WINDOW",
                  value: {
                    type: "form",
                    data: {
                      job: props.job,
                      corporation: props.corporation,
                      id: props.id,
                      action: "cv",
                      candidate: props.candidate
                    }
                  }
                });
              }}>
                <div className={"modern-link"}>
                  Voir le CV
                </div>
              </button>
            
            </div>
          </div>
        
        </div>
        <div > <div className={"text--bold"}>Matching </div>
          <div className={"candidate-page__matching-final"}>
            { average>100 && <span> 96 %</span>}
            { (average<70 || !average) && <span>En attente</span>}
            { average>70 && average<100 && <span> {average} %</span>}
          </div>
        </div>
      </div>
    
    </section>
  </>);
}