import React, {useContext, useEffect, useState} from "react";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import NavBar from "../sharedComponents/components/NavBar";
import Spaces from "../sharedComponents/components/Spaces";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {format} from "date-fns";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import Mission from "./componentsClientPage/Mission";
import {notification} from "../../utils/notification";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  const [loading, setLoading] = useState<"loading" | "error" | "forbidden" | "loaded">('loading');
  const token: string = props.match.params.token || "";
  const [missions, setMissions] = useState([]);
  const [candidates, setCandidates] = useState([]);
  console.log("candidates", candidates);
  
  /**
   * Tri des postes à afficher
   */
  const [displayedMissions, setDisplayedMissions] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterManager, setFilterManager] = useState('');
  const [managers, setManagers] = useState<any[]>([]);
  const [filterCategory, setFilterCategory] = useState('');
  const [categories, setCategories] = useState<any[]>([]);
  
  const [categoryToDisplay, setCategoryToDisplay] = useState<'inProgress' | 'recruited' | 'canceled'>('inProgress');
  
  const isResearchActive: boolean = !!filterText || !!filterStatus || !!filterManager || !!filterCategory;
  
  useEffect(() => {
    if(!filterText && !filterCategory && !filterStatus && !filterManager) {
      return setDisplayedMissions(missions);
    }
    
    let allMissions = [...missions];
    
    if(filterText) {
      allMissions = allMissions?.filter((item: any) => (item.positionWanted?.toLowerCase().includes(filterText?.toLowerCase())));
    }
    if(filterStatus) {
      allMissions = allMissions?.filter((item: any) => (item.blackBeltStatus?.toLowerCase() === filterStatus?.toLowerCase()));
    }
    if(filterManager) {
      allMissions = allMissions?.filter((item: any) => (item.blackBeltManager?.toLowerCase() === filterManager?.toLowerCase()));
    }
    if(filterCategory) {
      allMissions = allMissions?.filter((item: any) => (item.blackBeltCategory?.toLowerCase() === filterCategory?.toLowerCase()));
    }
    setDisplayedMissions(allMissions);
  }, [filterText, filterStatus, filterManager, filterCategory, missions]);
  
  /**
   * Get data for the page, and sets a timer to reload data
   */
  useEffect(() => {
    
    let timer: any;
  
    async function getData() {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/client/space`,
          {
            headers: {token: token},
            withCredentials: true
          })).data;
        console.log("answer", data)
        setMissions(data?.missions);
        // setCandidates(data.candidatesRecruited);

        console.log('-----------------', status)

        const managersRaw = (data?.missions ?? []).filter((item: any) => (!!item.blackBeltManager)).map((item: any) => {return (item.blackBeltManager?.toLowerCase())});
        const categoriesRaw = (data?.missions ?? []).filter((item: any) => (!!item.blackBeltCategory)).map((item: any) => {return (item.blackBeltCategory?.toLowerCase())})
  
        const managers: any[] = [...(new Set(managersRaw))];
        const categories: any[] = [...(new Set(categoriesRaw))];
  
        setManagers(managers);
        setCategories(categories);
        setLoading('loaded');
      } catch(error) {
        console.log(error);
      }
    }
    
    (async function() {
      try {
        await getData();
        timer = setInterval(() => {
          getData();
        }, 3 * 60 * 1000)
      } catch(error) {
        devLog(error);
      }
    })();
    
    return () => clearInterval(timer)
  }, []);
  
  return (<PageLayout pageStatus={loading}>
    
    <NavBar page={"ClientPage"} mainPageToken={token}/>
    
    <Spaces n={4}/>
    
    <h1 className={"client-page__title"}>Postes confiés à Black-belt</h1>
    
    <Spaces n={1}/>
    <div className={"client-page__filters"}>
      
      <div>
        <span className={"filter-title"}>Titre du poste</span><br/>
      <input className={"client-page__filter"} placeholder={"Titre du poste"} value={filterText}
             onChange={(e) => {setFilterText(e.target.value)}}/>
      </div>
      
      {/*<div>*/}
      {/*  <span className={"filter-title"}>Statut</span><br/>*/}
      {/*  <select className={"client-page__filter"} value={filterStatus}*/}
      {/*          onChange={(e) => {setFilterStatus(e.target.value);}}>*/}
      {/*    <option value={""}> Sélectionner un statut</option>*/}
      {/*    <option value={"inProgress"}>En cours</option>*/}
      {/*    <option value={"recruited"}>Recrutés</option>*/}
      {/*    <option value={"canceled"}>Annulés</option>*/}
      {/*  </select>*/}
      {/*</div>*/}
      
      <div>
        <span className={"filter-title"}>Personne en charge</span><br/>
        <select className={"client-page__filter"} value={filterManager}
                onChange={(e) => {setFilterManager(e.target.value);}}>
          <option value={""}>Sélectionner une personne en charge</option>
          {managers.map((item: any) => {return (<option value={item}> {item}</option>)})}
        </select>
      </div>
      
      <div>
        <span className={"filter-title"}>Catégorie</span><br/>
        <select className={"client-page__filter"} value={filterCategory}
                onChange={(e) => {setFilterCategory(e.target.value);}}>
          <option value={""}> Sélectionner une catégorie</option>
          {categories.map((item: any) => {return (<option value={item}> {item}</option>)})}
        </select>
      </div>
      
    </div>
    
    <Spaces n={1}/>
  
    <div className={"subnav"}>
      <button className={`subnav__button subnav__button--progress ${categoryToDisplay === "inProgress" ? "subnav__button--selected" : ""}`} onClick={() => {setCategoryToDisplay("inProgress")}}>
        En cours
      </button>
      <button className={`subnav__button subnav__button--recruited ${categoryToDisplay === "recruited" ? "subnav__button--selected" : ""}`} onClick={() => {setCategoryToDisplay("recruited")}}>
        Faits
      </button>
      <button className={`subnav__button subnav__button--canceled ${categoryToDisplay === "canceled" ? "subnav__button--selected" : ""}`} onClick={() => {setCategoryToDisplay("canceled")}}>
        Annulés
      </button>
    </div>
  
    {/*<Spaces n={0}/>*/}
    
    <div className={"client-page__missions"}>
      {displayedMissions?.filter((item: any) => (item.blackBeltStatus === categoryToDisplay)).map((item: any) => (
      <Mission isResearchActive={isResearchActive} item={item}/>))}
      {/*{displayedMissions.filter((item: any) => (item.blackBeltStatus === "inProgress")).map((item: any) => (*/}
      {/*  <Mission isResearchActive={isResearchActive} item={item}/>))}*/}
      {/*{displayedMissions.filter((item: any) => (item.blackBeltStatus === "recruited")).map((item: any) => (*/}
      {/*  <Mission isResearchActive={isResearchActive} item={item}/>))}*/}
      {/*{displayedMissions.filter((item: any) => (item.blackBeltStatus === "canceled")).map((item: any) => (*/}
      {/*  <Mission isResearchActive={isResearchActive} item={item}/>))}*/}
    </div>
    <Spaces n={1}/>
    
    {/*<div>*/}
    {/*  <h1 className={"client-page__title"}>Candidat(e)s recruté(e)s par Black-belt</h1>*/}
    {/*  <div className={"client-page__candidates-recruited"}>{candidates.map((item: any) => (*/}
    {/*    <div className={"client-page__candidate-recruited"}><img className={"client-page__avatar"}*/}
    {/*                                                             src={item.avatarUrl}/>*/}
    {/*      <div>{item.firstName + " " + item.lastName}  </div>*/}
    {/*    </div>))}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<Spaces n={1}/>*/}
    
    <div className={"text--center"}>
      Pour nous confier un nouveau recrutement, contacter Dan via le mail dan@black-belt.io ou par téléphone au 01 84 80
      60 62.
    </div>
    
    <Spaces n={1}/>
  </PageLayout>);

}