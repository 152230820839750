import React, {useContext, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import axios from "axios";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  const token = appContext.appPanels.window.windowOptions.data?.token;
  const [mail, setMail] = useState("");
  
  const [sent, setSent] = useState(false);
  
  if(sent) {
    return (<WindowLayout size={"small"} status={"ok"} windowTitle={"Partage du candidat"}>
      Lien envoyé avec succès.
    </WindowLayout>);
  }
  
  return (<WindowLayout size={"small"} status={"ok"} windowTitle={"Partage du candidat"}>
    
    <div>
      <input className={"candidate-page__tchat-input"}
             placeholder={"Email du destinataire"}
             value={mail}
             onChange={(e) => {
               setMail(e.target.value);
             }}/>
      
      <button className={"candidate-page__tchat-btn"}
              onClick={async() => {
        
                const {status, data} =
                  (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/share/candidate/by/mail`,
                    {
                      token: token, mail: mail,
                    }
                    , {
                      withCredentials: true,
                    })).data;
        
                if(status !== "ok") {
                  console.log(status);
                }
                setSent(true);
                // appContext.dispatchPanels({type: "CLOSE_WINDOW", value: null});
              }}>Envoyer
      </button>
    </div>
  </WindowLayout>);
}