import React, {useContext, useEffect, useReducer, useState} from "react";
import {format} from "date-fns";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import dataReducerBlackBeltNotes from "./dataReducerBlackBeltNotes";
import {notification} from "../../../utils/notification";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props: { candidate: any, business:any, token:any}) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;
    const [blackBeltNote, setBlackBeltNote] = useState("");
    const [blackBeltNoteFrom, setBlackBeltNoteFrom] = useState("");
    const [blackBeltNotes, dispatchBlackBeltNotes] : [any, Function] = useReducer(dataReducerBlackBeltNotes, []);


    useEffect(() => {
        (async function() {
            try {
                if(!!props.candidate.blackBeltNote)
                {dispatchBlackBeltNotes({
                    type: "INITIALIZE_DATA", value: props.candidate.blackBeltNote
                });}
                else{
                    {dispatchBlackBeltNotes({
                        type: "INITIALIZE_DATA", value: []
                    });}
                }
            } catch(error) {
                devLog(error);
            }
        })();
    }, [props.candidate]);

    return (<div>

        <div className={"candidate-page__tchat-container"}>
            {
                blackBeltNotes?.length === 0 && <div className={"text--center"}>Aucun message</div>
            }
            {(blackBeltNotes ?? []).map((item: any) => (<div className={"candidate-page__tchat-item"}>
                <div className={"margin15px"}></div>
                <div>
                    <div
                        className={"candidate-page__tchat-title"}> {item.from} - {item.dateOfCreation ? format(new Date(item.dateOfCreation), "dd/MM/yyyy") : ""}</div>
                    <div>{item.message}</div>
                </div>
            </div>)).reverse()}
        </div>
        <input className={"candidate-page__from-input"} placeholder={"Votre nom"} value={blackBeltNoteFrom}
               onChange={(e) => {
                   setBlackBeltNoteFrom(e.target.value);
               }}/>
        <input className={"candidate-page__note-input"} placeholder={"Votre message"} value={blackBeltNote}
               onChange={(e) => {
                   setBlackBeltNote(e.target.value);
               }}/>

        <button className={"candidate-page__tchat-btn"} disabled={!blackBeltNote || !blackBeltNoteFrom} onClick={async () => {
            const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/note`,
                {
                    candidacyId:props.candidate.candidacyId,
                    message:blackBeltNote,
                    firstName:blackBeltNoteFrom,
                    token:props.token,
                }
                , {
                    timeout: 5000,
                    withCredentials: true,
                })).data;

            if (status !== "ok") {
                return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            }

            dispatchBlackBeltNotes({
                type: "UPDATE_CANDIDACY", value: {
                    dateOfCreation: new Date(),
                    from: blackBeltNoteFrom,
                    message: blackBeltNote
                }
            });
            setBlackBeltNote("");
            setBlackBeltNoteFrom("");
            return notification(appContext.dispatchPanels, "message envoye", "success");

        }}>Envoyer
        </button>
    </div>);
}