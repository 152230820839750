import React from "react";
import {SingleStep} from "../publicSpacePages/componentsCandidatePage/CPSteps";

export default (props: {
  corporation: string;
}) => {
  
  return (<>
    <section className={"flash-card-steps-container"}>
      <div className={"candidate-page__steps"}>
  
        <SingleStep date={new Date()} title={"Transmission de la candidature"} color={"green"} />
  
        <SingleStep date={""} title={"Validation ou rejet"} color={"grey"} />
  
        <SingleStep date={""} title={"Entretien Black-belt"} color={"grey"} />
  
        <SingleStep date={""} title={`Entretien ${props.corporation}`} color={"grey"} />
  
        <SingleStep date={""} title={"Promesse d'embauche"} color={"grey"} />
  
        <SingleStep date={""} title={"Onboarding"} color={"grey"} />
        
      </div>
    </section>
  </>);
}