import {IAppPanels} from "../state/IAppPanel";
import {initialPanels} from "../state/initialPanels";

export {panelsReducer};

export type TPanelsReducers =
  "OPEN_WINDOW" |
  "CLOSE_WINDOW" |
  "CLOSE_ALL"|
    "OPEN_NOTIFICATIONS"|
    "CLOSE_NOTIFICATIONS";

const panelsReducer = (state: IAppPanels, action: {
  type: TPanelsReducers,
  value: any,
}) => {
  
  switch(action.type) {
    
    case 'OPEN_WINDOW':
      return ({
        ...state,
        window: {
          isWindowVisible: true,
          isWindowClosing: false,
          windowOptions: {
            type: action.value.type,
            data: action.value.data,
          },
        },
      });

    case 'CLOSE_WINDOW':
      return ({
        ...state,
        window: {
          isWindowVisible: false,
          isWindowClosing: false,
          windowOptions: {
            type: "",
            data: {},
          },
        },
      });

    case 'OPEN_NOTIFICATIONS':
      return ({
        ...state,
        notifications: {
          isWindowNotificationVisible: true,
          notificationText: action.value.text,
          notificationType: action.value.type,
          notificationTimer: null,
        }
      });

    case 'CLOSE_NOTIFICATIONS':
      return ({
        ...state,
        notifications: {
          isWindowNotificationVisible: false,
          notificationText: "",
          notificationType: "information",
          notificationTimer: null,
        }
      });
      
    // a lot todo here
  
    case 'CLOSE_ALL':
      return ({
        ...state,
        ...initialPanels,
      });
  
    default:
      throw new Error(`Type ${action.type} is not defined in panelsReducer`);
  }
  
}