import React, {useEffect, useState} from "react";

export default (props: { candidate: any, }) => {
  
  const [displayVid, setDisplayVid] = useState(true);
  
  useEffect(() => {
    setDisplayVid(false);
    const time = setTimeout(() => setDisplayVid(true), 200);
    return () => clearTimeout(time);
  }, [props.candidate?.blackBeltVideo]);
  
  if(!displayVid) {
    return (<section className={"flash-card-container"}>
  
      <div className={"no-video"}>
        {props.candidate.avatarUrl && <img className={"no-video__img"} src={props.candidate.avatarUrl}/>}
      </div>
    </section>)
  }
  
  return (<>
    <section className={"flash-card-container"}>
      {
        !props.candidate?.blackBeltVideo && !props.candidate.blackBeltLoomLink && <div className={"no-video"}>
          {props.candidate.avatarUrl && <img className={"no-video__img"} src={props.candidate.avatarUrl}/>}
          <div className={"no-video__text"}>Pas de vidéo</div>
        </div>
      }
      {
        props.candidate?.blackBeltVideo && !props.candidate.blackBeltLoomLink &&<div className={"flash-card-container--video"}>
        <video playsInline={true} controls={true} autoPlay={false} loop={false}
               muted={false}>
          <source src={props.candidate?.blackBeltVideo} type="video/mp4"/>
        </video></div>
      }
      {
        props.candidate.blackBeltLoomLink && <a href={props.candidate.blackBeltLoomLink} target={"_blank"}>
          <div className={"no-video"}>
            {
              props.candidate.avatarUrl && <img className={"no-video__img"} src={props.candidate.avatarUrl}/>
            }
            <div className={"no-video__text"}>
              Lancer la vidéo
              <img className={"no-video__text__play"} src={"https://static.myshortlist.co/play.svg"} />
            </div>
          </div>
        </a>
      }
    
    </section>
  </>);
}