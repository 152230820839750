import React from "react";
import WindowLayout from "../../layouts/WindowLayout";

export default (props: any) => {
  
  return (<WindowLayout size={"big"} status={"ok"} windowTitle={""}>
  
    <div className="calendly-inline-widget" data-url="https://calendly.com/hello-blackbelt/15min?hide_gdpr_banner=1" style={{
      "minWidth": "320px",
      "height": "630px"
    }}></div>
    
  </WindowLayout>);
}