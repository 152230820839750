import React, {useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  const [comment, setComment] = useState('')
  
  return (<>
    
    <Spaces n={0}/>
    
    {/*<div className={"text--center"}>*/}
    {/*<input className={"comment"} value={comment} onChange={(e) => {*/}
    {/*  setComment(e.target.value);*/}
    {/*}} type={"text"} placeholder={"Laissez un commentaire pour Black-belt"} />*/}
    {/*</div>*/}
    
    {/*<Spaces n={0}/>*/}
    
    <div className={"space-flex--center"}>
      <button className={"button--linkedin"} onClick={async() => {
        props.setEvaluationStep(2);
        // props.sendEvaluation("no", comment);
      }}>
        Je ne suis pas interessé(e) par ce profil
      </button>
      
      <button className={"button--linkedin"} onClick={async() => {
        props.setEvaluationStep(3);
        props.sendEvaluation("yes", comment);
      }}>
        Ce candidat m'interésse!
      </button>
    
    </div>
  
  </>);
}
