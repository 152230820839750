import React, {useContext} from "react";
import {format} from "date-fns";
import {devLog} from "../../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import {notification} from "../../../utils/notification";

export default (props: {
  item: any;
  isResearchActive: any;
}) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  
  return (<div className={`client-page__mission ${props.isResearchActive ? "client-page__mission--research" : ""}`}>
    <div className={"client-page__mission__left"}>
      <div className={"hide-on-smartphones client-page__mission__candidates-number"}>
        {props.item.nCandidates}
        <span>Candidats</span>
      </div>
      <div>
        <div className={"client-page__mission__item"}><span
          className={"client-page__date__job-title"}>{props.item.positionWanted}</span>
          {/*<span*/}
          {/*className={"client-page__date"}>{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd/MM/yyyy") : ""}</span>*/}
        </div>
        {/*<div className={"client-page__mission__item"}>{item.contract.toUpperCase()}</div>*/}
        {/*<div className={"client-page__mission__item"}>Localisation:{item.location.city}</div>*/}
        {/*<div className={"client-page__mission__item"}>Fourchette REM: {item.salaryMin}-{item.salaryMax}</div>*/}
        <div className={"client-page__mission__item text--grey"}>Package salarial: {props.item.salaryPackage ?? "--"}</div>
        <div className={"client-page__mission__avatars"}>
          {props.item?.avatars && props.item?.avatars?.length !== 0 && props.item?.avatars?.map((url: string) => (<img src={url}/>))}
        </div>
      </div>
    </div>
    
    <div className={"client-page__mission__items"}>
      {props.item.blackBeltStatus === 'inProgress' && <div className={"client-page__mission__status client-page__mission__status--blue"} title={"Statut"}>EN COURS</div>}
      {props.item.blackBeltStatus === 'recruited' && <div className={"client-page__mission__status client-page__mission__status--green"} title={"Statut"}>RECRUTÉ</div>}
      {props.item.blackBeltStatus === 'canceled' && <div className={"client-page__mission__status client-page__mission__status--orange"} title={"Statut"}>ANNULÉ</div>}
      <button className={"client-page__btn"}  onClick={async() => {
        try {
          appContext.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
              type: "statsOfMission",
              data: {
                missionId: props.item.id
              }
            }
          });
        } catch(error) {
          devLog(error);
        }
      }}>  Statistiques </button>
      <button className={"client-page__btn"} onClick={async() => {
        try {

          appContext.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
              type: "candidatesOfMission",
              data: {
                missionId: props.item.id
              }
            }
          });
        } catch(error) {
          devLog(error);
        }
      }}> Candidats
      </button>
    </div>
  </div>);
}