import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";
import CPESwitchEvaluationSteps from "./CPESwitchEvaluationSteps";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: {
  candidate: any;
}) => {
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  const [evaluationStep, setEvaluationStep] = useState(0);
  const [keep, setKeep] = useState("");

  useEffect(() => {
    (async function() {
      try {
        setKeep(props.candidate.blackBeltKeep);
        if(props.candidate.blackBeltKeep === "yes" || props.candidate.blackBeltKeep === "no") {
          setEvaluationStep(3);
        } else {
          setEvaluationStep(1);
        }
        
      } catch(error) {
        devLog(error);
      }
    })();
  }, [props.candidate]);
  
  const sendEvaluation = async(newValue: string, newComment: string) => {
    try {
      setKeep(newValue);
      await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/keep/candidacy`, {
        candidacyId: props.candidate.candidacyId,
        keep: newValue,
        comment: newComment
      }, {withCredentials: true});
      window.location.reload();
      return notification(appContext.dispatchPanels, "Evaluation sauvegardee", "success");

    } catch(error) {
      notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
      throw error;
    }
  }
  
  return (<>
    <section className={"flash-card-container"}>
      
      <span className={"candidate-page__title text--orange"}>Next step pour ce candidat:</span>
      <span className={""}> {props.candidate?.blackBeltNextStep}</span>
      
      <CPESwitchEvaluationSteps evaluationStep={evaluationStep}
                                setEvaluationStep={setEvaluationStep}
                                setKeep={setKeep}
                                keep={keep}
                                candidateToEvaluate={props.candidate}
                                sendEvaluation={sendEvaluation}/>

    </section>
  </>);
}

