import Spaces from "../../sharedComponents/components/Spaces";
import React from "react";
import CPEGrade from "./CPEGrade";
import CPEMessage from "./CPEMessage";
import CPEChoice from "./CPEChoice";
import CPEComment from "./CPEComment";

export default(props: any) =>{

    switch(props.evaluationStep) {
        
        case 1:
            return (<CPEGrade {...props}  />);

        case 2:
            return (<CPEComment {...props} />);
            
        case 3:
            return (<CPEChoice {...props} />);

        default:
            return (<><Spaces n={5}/>Cette page n'existe pas<Spaces n={3}/></>);
    }
}