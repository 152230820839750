import React, {useContext} from "react";
import CPESwitchEvaluationSteps from "../publicSpacePages/componentsCandidatePage/CPESwitchEvaluationSteps";
import Spaces from "../sharedComponents/components/Spaces";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";

export default (props: {
  candidate: any;
  job: string;
  corporation: string;
  id: string;
}) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (<>
    <section className={"flash-card-container"}>
    
      <span className={"candidate-page__title text--orange"}>Next step pour ce candidat: </span>
      <span className={""}>Validation du profil par {props.corporation}</span>
    
      <Spaces n={0}/>
      
      <div className={"text--center"}>
        <button className={"button--linkedin"} onClick={() => {
          // @ts-ignore
          Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
          return false;
        }}>
          Je souhaite rencontrer un autre candidat
        </button>
        <button className={"button--linkedin"} onClick={() => {
          // @ts-ignore
          Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
          return false;
        }}>
          Ce candidat m'intéresse
        </button>
      </div>
  
      <Spaces n={0}/>

    </section>
  </>);
}