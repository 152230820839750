import React, {useContext, useEffect, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import {Link} from "react-router-dom";
import axios from "axios";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  
  const [windowStatus, setWindowStatus] = useState<"pending" | "ok">("pending");
  
  const [candidates, setCandidates] = useState([]);
  console.log("candidates",candidates);
  const [client, setClient] = useState<any>({});
  
  const missionId = appContext.appPanels.window.windowOptions.data?.missionId;
  
  useEffect(() => {
    (async() => {
      try {
        const {
          status,
          data
        } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/candidates/for/mission`,
          {
            headers: {missionid: missionId},
            withCredentials: true
          })).data;
        setWindowStatus("ok")
        setCandidates(data.candidates);
        setClient(data.client);
      } catch(error) {
        console.log(error);
      }
    })();
  }, []);
  
  return (<WindowLayout size={"big"} status={windowStatus} windowTitle={"Candidats proposés pour ce poste"}>
    
    {(!candidates || candidates.length === 0) && <div className={"text--center text--grey"}>
      Aucun candidat à afficher
    </div>}

    {candidates?.filter((item:any)=>(item.kanbanColumn==="10")).map((item: any) => {
      let token;
      for(let sharingLink of item.sharingLinks) {
        if(sharingLink.tokenName?.toLowerCase() === client?.name?.toLowerCase()) {
          token = sharingLink.token
        }
      }
      return (
          <>
            <Link to={`/candidat/${token}`}>
              <div className={"client-page__candidate client-page__candidate--recruited"} onClick={() => {
                appContext.dispatchPanels({
                  type: "CLOSE_WINDOW",
                  value: {
                    type: "",
                    data: {}
                  }
                });
              }}>
                <div className={"client-page__avatar-container"}><img className={"client-page__avatar"}
                                                                      src={item.avatarUrl}/></div>
                <div className={"client-page__candidate__block__name"}>
                  <div className={"client-page__candidate__name"}>{item.firstName ?? ""} {item.lastName ?? ""}</div>
                  {/*<div>{item.email}</div>*/}
                  <div>{item.phone}</div>
                </div>
                <div>
                  <div>Recruté</div>
                </div>
                {/*<div className={"client-page__evaluation"}>*/}
                {/*  {item.blackBeltKeep === "yes" && <div> Vous avez souhaite continue avec ce candidat</div>}*/}
                {/*  {item.blackBeltKeep === "no" && <div> Vous n'avez pas souhaite continue avec ce candidat</div>}*/}
                {/*  {item.blackBeltKeep !== "no" && item.blackBeltKeep !== "yes" &&*/}
                {/*  <div> Vous n'avez pas encore evalue ce candidat</div>}*/}
                {/*</div>*/}
              </div>
            </Link>
          </>);
    })}
    
    {candidates?.filter((item:any)=>(item.kanbanColumn!=="10")).map((item: any) => {
      let token;
      for(let sharingLink of item.sharingLinks) {
        if(sharingLink.tokenName?.toLowerCase() === client?.name?.toLowerCase()) {
          token = sharingLink.token
        }
      }
      return (
        <>
          <Link to={`/candidat/${token}`}>
            <div className={"client-page__candidate"} onClick={() => {
              appContext.dispatchPanels({
                type: "CLOSE_WINDOW",
                value: {
                  type: "",
                  data: {}
                }
              });
            }}>
              <div className={"client-page__avatar-container"}><img className={"client-page__avatar"}
                                                                    src={item.avatarUrl}/></div>
              <div className={"client-page__candidate__block__name"}>
                <div className={"client-page__candidate__name"}>{item.firstName ?? ""} {item.lastName ?? ""}</div>
                {/*<div>{item.email}</div>*/}
                <div>{item.phone}</div>
              </div>
              <div>
                <div>Prochaine étape:</div>
                <div>{item.blackBeltNextStep}</div>
              </div>
              {/*<div className={"client-page__evaluation"}>*/}
              {/*  {item.blackBeltKeep === "yes" && <div> Vous avez souhaite continue avec ce candidat</div>}*/}
              {/*  {item.blackBeltKeep === "no" && <div> Vous n'avez pas souhaite continue avec ce candidat</div>}*/}
              {/*  {item.blackBeltKeep !== "no" && item.blackBeltKeep !== "yes" &&*/}
              {/*  <div> Vous n'avez pas encore evalue ce candidat</div>}*/}
              {/*</div>*/}
            </div>
          </Link>
        </>);
    })}
  
  </WindowLayout>);
}