import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {format} from "date-fns";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {notification} from "../../utils/notification";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import NavBar from "../sharedComponents/components/NavBar";
import Spaces from "../sharedComponents/components/Spaces";
import ButtonEdit from "../sharedComponents/buttons/ButtonDateEdit";
import ButtonDateEdit from "../sharedComponents/buttons/ButtonDateEdit";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
    const token: string = props.match.params.token || "";

    const [windowStatus, setWindowStatus] = useState<"pending" | "ok">("pending");
    const [candidates, setCandidates]: any[] = useState();
    const [commentary, setCommentary] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/onboarding`,
                    {
                        headers: {token: token},
                        withCredentials: true
                    })).data;
                setWindowStatus("ok");
                setCandidates(data);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function updateCandidacy(candidacyId: any, type: string, value: any) {
        try {
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/date`,
                {
                    candidacyId: candidacyId,
                    type: type,
                    value: value
                }
                , {
                    timeout: 5000,
                    withCredentials: true,
                })).data;

            if(status !== "ok"){
                return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            }

            const candidate = candidates.find((item: any) => (item.candidacyId === candidacyId));

            if (type === "entryDate") {
                setCandidates((ps: any) => (ps.map((item: any) => {
                    if (item.candidacyId !== candidacyId) {
                        return item
                    } else {
                        return {
                            firstName: candidate.firstName,
                            lastName: candidate.lastName,
                            positionWanted: candidate.positionWanted,
                            candidacyId: candidate.candidacyId,
                            entryDate: value,
                            endOfInitialTrialPeriod: candidate.endOfInitialTrialPeriod,
                            endOfTrialPeriod: candidate.endOfTrialPeriod,
                            internalCommentary: candidate.internalCommentary,
                            token: candidate.token,
                        }
                    }
                })));
            }

            if (type === "endOfInitialTrialPeriod") {
                setCandidates((ps: any) => (ps.map((item: any) => {
                    if (item.candidacyId !== candidacyId) {
                        return item
                    } else {
                        return {
                            firstName: candidate.firstName,
                            lastName: candidate.lastName,
                            positionWanted: candidate.positionWanted,
                            candidacyId: candidate.candidacyId,
                            entryDate: candidate.entryDate,
                            endOfInitialTrialPeriod: value,
                            endOfTrialPeriod: candidate.endOfTrialPeriod,
                            internalCommentary: candidate.internalCommentary,
                            token: candidate.token,
                        }
                    }
                })));
            }

            if (type === "endOfTrialPeriod") {
                setCandidates((ps: any) => (ps.map((item: any) => {
                    if (item.candidacyId !== candidacyId) {
                        return item
                    } else {
                        return {
                            firstName: candidate.firstName,
                            lastName: candidate.lastName,
                            positionWanted: candidate.positionWanted,
                            candidacyId: candidate.candidacyId,
                            entryDate: candidate.entryDate,
                            endOfInitialTrialPeriod: candidate.endOfInitialTrialPeriod,
                            endOfTrialPeriod: value,
                            internalCommentary: candidate.internalCommentary,
                            token: candidate.token,
                        }
                    }
                })));
            }

            if (type === "internalCommentary") {
                setCandidates((ps: any) => (ps.map((item: any) => {
                    if (item.candidacyId !== candidacyId) {
                        return item
                    } else {
                        return {
                            firstName: candidate.firstName,
                            lastName: candidate.lastName,
                            positionWanted: candidate.positionWanted,
                            candidacyId: candidate.candidacyId,
                            entryDate: candidate.entryDate,
                            endOfInitialTrialPeriod: candidate.endOfInitialTrialPeriod,
                            endOfTrialPeriod: candidate.endOfTrialPeriod,
                            internalCommentary: value,
                            token: candidate.token,
                        }
                    }
                })));
            }
            return notification(appContext.dispatchPanels, "information sauvegardee", "success");

        } catch (error) {
            console.log(error);
            return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    return (<PageLayout pageStatus={"loaded"}>

        <NavBar page={"ClientPage"} mainPageToken={token}/>

        <Spaces n={4}/>

        <h1 className={"client-page__title"}>Onboarding</h1>

        <Spaces n={1}/>
        <table style={{margin: "auto"}} className={"onboarding"}>
            <tr>
                <th> Nom</th>
                <th> Poste</th>
                <th> Date d'embauche</th>
                <th> Fin de periode d'essai initiale</th>
                <th> Fin de periode d'essai</th>
                <th> Commentaire</th>
            </tr>
            {candidates?.map((item: any) => (<tr>
                <td>
                    <Link to={`/candidat/${item.token}`} target={"-blank"}>
                        <span className={"modern-link"}>{item.firstName + " " + item.lastName}</span>
                    </Link>
                </td>
                <td>{item.positionWanted}</td>
                <td>
                    <div className={"row"}>
                    {item.entryDate ? format(new Date(item.entryDate), "dd-MM-yyyy") : ""}
                    <ButtonDateEdit value={candidates.find((candidate: any) => (item.candidacyId === candidate.candidacyId)).entryDate}
                                onClick={updateCandidacy}
                                candidacyId={item.candidacyId}
                                typeOfValue={"entryDate"}
                                inputType={"Date"}/>
                    </div>
                </td>
                <td>
                    <div className={"row"}>
                        {item.endOfInitialTrialPeriod && ((((new Date(item.endOfInitialTrialPeriod).getTime() ?? 0) - (Date.now() ?? 0)) / (1000 * 60 * 60 * 24)) < 15 ||
                            (((Date.now() ?? 0) - ((new Date(item.endOfInitialTrialPeriod).getTime() ?? 0)) / (1000 * 60 * 60 * 24)) < 1)) &&
                        <div className={"window-onboarding__alert"}></div>}
                        {item.endOfInitialTrialPeriod ? format(new Date(item.endOfInitialTrialPeriod), "dd-MM-yyyy") : ""}
                        <ButtonDateEdit value={candidates.find((candidate: any) => (item.candidacyId === candidate.candidacyId)).endOfInitialTrialPeriod}
                                        onClick={updateCandidacy}
                                        candidacyId={item.candidacyId}
                                        typeOfValue={"endOfInitialTrialPeriod"}
                                        inputType={"Date"}/>
                    </div>
                </td>
                <td>
                    <div className={"row"}>
                        {item.endOfTrialPeriod && ((((new Date(item.endOfTrialPeriod).getTime() ?? 0) - (Date.now() ?? 0)) / (1000 * 60 * 60 * 24)) < 15 ||
                            (((Date.now() ?? 0) - ((new Date(item.endOfTrialPeriod).getTime() ?? 0)) / (1000 * 60 * 60 * 24)) < 1)) &&
                        <div className={"window-onboarding__alert"}></div>}
                        {item.endOfTrialPeriod ? format(new Date(item.endOfTrialPeriod), "dd-MM-yyyy") : ""}
                        <ButtonDateEdit value={candidates.find((candidate: any) => (item.candidacyId === candidate.candidacyId)).endOfTrialPeriod}
                                        onClick={updateCandidacy}
                                        candidacyId={item.candidacyId}
                                        typeOfValue={"endOfTrialPeriod"}
                                        inputType={"Date"}/>
                    </div>
                </td>
                <td> <div className={"row"}>{item.internalCommentary}
                    <ButtonDateEdit value={candidates.find((candidate: any) => (item.candidacyId === candidate.candidacyId)).internalCommentary}
                                    onClick={updateCandidacy}
                                    candidacyId={item.candidacyId}
                                    typeOfValue={"internalCommentary"}
                                    inputType={"text"}/>
                </div>
                </td>
            </tr>))}
        </table>
    </PageLayout>);
}

