import React, {useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  const [comment, setComment] = useState('')
  
  return (<>
    
    <Spaces n={0}/>
    
    Pourquoi n'êtes-vous pas interessé(e) par ce profil ?
  
    <Spaces n={0}/>
    
    <div className={"text--center"}>
    <input className={"comment"} value={comment} onChange={(e) => {
      setComment(e.target.value);
    }} type={"text"} placeholder={"Laissez un commentaire pour Black-belt"} />
    </div>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--center"}>
      <button className={"button--linkedin"} onClick={async() => {
        props.setEvaluationStep(3);
        props.sendEvaluation("no", comment);
      }}>
        Poursuivre
      </button>
    
    </div>
  
  </>);
}
