import React, {useState} from "react";
import ButtonCircle from "./ButtonCircle";

export default (props: {
    onClick: Function;
    inputType:string;
    value:any;
    candidacyId:string;
    typeOfValue:string;
}) => {

    const [activeEdit, setActiveEdit] = useState(false);
    const [value, setValue] = useState(props.value);


    return(<>
        {
            !activeEdit && <>
                <ButtonCircle title={"Editer"} alt={"delete"} imgUrl={"https://static.myshortlist.co/btn-pen.svg"} onClick={() => {
                        setActiveEdit(true);
                }} />
            </>
        }
        {
            activeEdit && <>
                <input type={props.inputType} value={value}  onChange={(e)=>(setValue(e.target.value))}/>
                <ButtonCircle title={"Annuler"} alt={"cancel"} imgUrl={"https://static.myshortlist.co/btn-cross.svg"} onClick={() => setActiveEdit(false)} />
                <ButtonCircle title={"Enregistrer"} alt={"confirm"} imgUrl={"https://static.myshortlist.co/btn-check.svg"} onClick={() => {
                    props.onClick(props.candidacyId,props.typeOfValue,value);
                    setActiveEdit(false);
                }} />
            </>
        }
    </>);
}