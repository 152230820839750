import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import Spaces from "../Spaces";
import {devLog} from "../../../../utils/devLog/devLog";
import ButtonDelete from "../../buttons/ButtonDelete";
import {notification} from "../../../../utils/notification";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  const token = appContext.appPanels.window.windowOptions.data.token || {};
  const [managers, setManagers]: any = useState([]);
  const [businessId, setBusinessId] = useState("");
  
  useEffect(() => {
    (async function() {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/client`,
          {
            headers: {token: token},
            withCredentials: true
          })).data;
        setManagers(data.managers ?? []);
        setBusinessId(data.businessId);
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  const [managerName, setManagerName] = useState('');
  const [managerMail, setManagerMail] = useState('');
  
  return (<WindowLayout size={"big"} status={"ok"} windowTitle={"Managers de l'entreprise"}>
    
    <div className={"text--grey text--center"}>
      Ajouter des personnes vous permet de les notifier et/ou solliciter sur des flash-cards de candidats.
    </div>
    
    <Spaces n={0}/>
    
    {managers?.map((item: any) => (<div className={"business--blackbelt__manager"}>
      <div className={"business--blackbelt__manager-fullName"}>{item.fullName}</div>
      <div className={"business--blackbelt__manager-email"}>{item.email}</div>
      <div><ButtonDelete validation={true}
                         onClick={async() => {
                           try {
                             const {
                               status,
                               data
                             } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/delete/manager`,
                               {
                                 businessId: businessId,
                                 managerId: item._id,
                               }, {
                                 withCredentials: true
                               })).data;


                             if(status === "ok") {
                               setManagers((previousState: any) => (
                                 previousState.filter((manager: any) => (manager._id !== item._id))));
                               return notification(appContext.dispatchPanels, "manager supprime", "success");
                             }

                               return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");

                           } catch(error) {
                             console.log(error);
                             return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");

                           }
                         }}/>
      </div>
    </div>))}
    
    <Spaces n={1}/>
    <div className={""}>
      <h1 className={"business--blackbelt__add-manager--title"}>Ajouter un manager</h1>
      <div className={"business--blackbelt__add-manager"}>
        
        <input type={"text"} placeholder={"Nom Complet"} className={"client-page__filter"}
               value={managerName} onChange={(e) => setManagerName(e.target.value)}/>
        <input type={"text"} placeholder={"Email"} className={"client-page__filter"}
               value={managerMail} onChange={(e) => setManagerMail(e.target.value)}/>
        
        
        <button className={"client-page__btn"} onClick={async() => {
          try {
            const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/add/manager`,
              {
                businessId: businessId,
                name: managerName.trim(),
                mail: managerMail.trim(),
              }, {
                withCredentials: true
              })).data;
            if(status === "ok") {
              setManagers((previousState: any) => ([
                ...previousState,
                {
                  fullName: managerName,
                  email: managerMail
                }
              ]));
              return notification(appContext.dispatchPanels, "manager ajoute", "success");
            }
          } catch(error) {
            console.log(error);
            return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");

          }
        }}> Save
        </button>
      </div>
    </div>
  
  </WindowLayout>);
}