import React, {useContext} from "react";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";

export default (props: {
  job: string;
  corporation: string;
  id: string;
  candidate: any;
}) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (<>
    <section className={"flash-card-container"}>
      <h1 className={"candidate-page__title text--blue"}>Contrôles de références:</h1>
      <div className={"text--center"}>
        <button className={"button--linkedin"}
                title={"Nous avons prit les références de ce candidat ou de cette candidate pour vous faire gagner du temps !"}
                onClick={()=>{
          appContext.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
              type: "form",
              data: {
                job: props.job, corporation: props.corporation, id: props.id, action: "refs", candidate: props.candidate
              }
            }
          });
        }}>
          Voir les 3 références
        </button>
      </div>
    </section>
    </>);
}