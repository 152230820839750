import React, {useContext} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props: { candidate: any, token:string}) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    const average=Math.round(((props.candidate?.blackBeltCulture *20)
        +(props.candidate?.blackBeltHardSkills)
        +(props.candidate?.blackBeltSoftSkills *20))/3);
    
    function getCvUrl(candidate: any) {
        if(candidate?.cvUrls?.length !== 0) {
            return (candidate?.cvUrls ?? [])[(candidate?.cvUrls?.length ?? 1) - 1] ?? "";
        }
        if(candidate.cvUrl) {
            return candidate?.cvUrl ?? "";
        }
        return "";
    }

    return (<>
        <section className={"flash-card-container header"}>
            <div className={"candidate-page__space-between"}>

                <div>
                    <button className={"button--linkedin"} onClick={()=>{
                        console.log("hit 1");
                        appContext.dispatchPanels({
                            type: "OPEN_WINDOW",
                            value: {
                                type: "shareCandidate",
                                data: {
                                    token: props.token,
                                }
                            }
                        });
                    }}>Partager</button>
                </div>

            <div className={"header--up"}>

                <div className={"space-flex--center"}>
                    <div className={"header__avatar-container"}>
                        <img className={"header__avatar"} src={props.candidate?.avatarUrl}
                             alt={"avatar"}/>
                    </div>
                </div>

                <div className={"header__name text--center"}>
                    {props.candidate?.firstName?.toUpperCase()} {props.candidate?.lastName?.toUpperCase()}
                </div>

                <div className={"column"}>
                    <div>
                        <a href={props.candidate?.linkedin}
                           target={"_blank"}>
                            <button className={"btn--hollow header__linkedin"} disabled={!props.candidate?.linkedin}>
                                <div className={"modern-link"}>
                                    Voir le profil Linkedin
                                </div>
                            </button>
                        </a>
                    </div>

                    <div>{getCvUrl(props.candidate) && <a href={getCvUrl(props.candidate)} target={"_blank"}>
                        <button className={"btn--hollow header__linkedin"}>
                            <div className={"modern-link"}>
                                Voir le CV
                            </div>
                        </button>
                        </a>
                    }
                    </div>
                </div>

            </div>
                <div > <div className={"text--bold"}>Matching </div>
                    <div className={"candidate-page__matching-final"}>
                        { average>100 && <span> 96 %</span>}
                        { (average<70 || !average) && <span> En attente </span>}
                        { average>70 && average<100 && <span> {average} %</span>}
                    </div>
                </div>
            </div>

        </section>
    </>);
}