import React from "react";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export default (props: any) => {
  
  return (<>
    <section className={"flash-card-container"}>
      <h1 className={"candidate-page__title text--blue"}>Matching:</h1>
      <div className={"space-flex--up space-flex--up__cellphone"}>
        <div className={"column margin30px"} title={"La note de culture-fit indique la correspondance entre l'état d'esprit du candidat ou de la candidate avec l'état d'esprit de l'entreprise"}>
          <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
              value={props.candidate?.blackBeltCulture*20}
              text={`${props.candidate?.blackBeltCulture}/5`}
              styles={buildStyles({
                textSize: '30px',
                pathColor: `#3898ec`,
                textColor: `#3898ec`,})}/>
          </div>
          <div> Culture fit </div>
        </div>
        <div className={"column margin30px"} title={"La note de compétence correspond aux compétences du candidat ou de la candidate pour le poste"}>
          <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
              value={props.candidate?.blackBeltHardSkills}
              text={`${props.candidate?.blackBeltHardSkills}%`}
              styles={buildStyles({
                textSize: '30px',
                pathColor: `#2ec43d`,
                textColor: `#2ec43d`,})}/>
          </div>
          <div> Competences </div>
        </div>
        <div className={"column margin30px"} title={"La note de culture-fit indique la capacité du candidat ou de la candidate à s'intégrer dans vos équipes"}>
          <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
              value={props.candidate?.blackBeltSoftSkills*20}
              text={`${props.candidate?.blackBeltSoftSkills}/5`}
              styles={buildStyles({
                textSize: '30px',
                pathColor: `#ec8038`,
                textColor: `#ec8038`,})}/>
          </div>
          <div> Soft Skills </div>
        </div>
      </div>
    </section>
  </>);
}