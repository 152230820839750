type TState =string[];

export default (previousState: TState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return ([
                ...action.value
            ]);


        case 'UPDATE_CANDIDACY':
            return ([
                ...previousState,
                action.value,
            ]);
    }
}
