import React, {useContext} from "react";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";

export default (props: {
  corporation: string;
  job: string;
  id: string;
}) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (<>
    <div className={"navbar"}>
    
      <div className={"navbar__left"}>
        <div className={"navbar__logo"}>Black-belt</div>
        <div className={"hide-on-smartphones space--small navbar__position"}>{props.corporation} - {props.job}</div>
      </div>
    
      <div className={"navbar__right"}>
        <button className={"navbar__btn2 hide-on-smartphones"}
                title={"Consultez vos statistiques de recrutements pour identifier ce qui bloque !"}
                onClick={() => {
                  // @ts-ignore
                  Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
                  return false;
                }}>Mes statistiques</button>
        <button className={"navbar__btn2 hide-on-smartphones"}
                title={"Suivez vos recrutements et recevez des alertes"}
                onClick={() => {
                  // @ts-ignore
                  Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
                  return false;
                }}>Mes onboardings</button>
        <button className={"navbar__btn2 hide-on-smartphones"}
                title={"Consultez l'avancement de tous vos postes"}
                onClick={() => {
                  // @ts-ignore
                  Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
                  return false;
                }}>Mes postes</button>
  
        <button className={"navbar__btn hide-on-smartphones"}
                onClick={() => {
                  // @ts-ignore
                  Calendly.initPopupWidget({url: 'https://calendly.com/hello-blackbelt/15min'});
                  return false;
                }}>Réserver une démo</button>
      </div>
    </div>
  </>);
}