import React, {useState} from "react";
import CPTchat from "./CPTchat";
import CPCandidacy from "./CPCandidacy";
import Spaces from "../../sharedComponents/components/Spaces";
import CPNotes from "./CPNotes";


export default (props: { candidate: any, business:any, token:any}) => {
    
    const [step, setStep] = useState("tchat");

    return (<>
        <section className={"flash-card-container candidate-page__tchat"}>
            <div className={"row"}>
                <button className={`button--black ${step!=="tchat"? "button--linkedin--transparent":""}`} onClick={() => {
                    setStep("tchat");
                }}>
                    Échangez avec black belt
                </button>
                
                {/*<button className={`button--black ${step!=="answers"? "button--linkedin--transparent":""}`} onClick={() => {*/}
                {/*    setStep("answers");*/}
                {/*}}>*/}
                {/*    Reponses du candidat*/}
                {/*</button>*/}

                <button className={`button--black ${step!=="notes"? "button--linkedin--transparent":""}`} onClick={() => {
                    setStep("notes");
                }}>
                    Notes internes sur le/la candidat(e)
                </button>
            </div>
    
            <Spaces n={0}/>

            {step==="tchat" &&<div className={"text--grey text--center"}>
                Discutez avec l'équipe de Black-belt. Le candidat n'aura pas accès à cette discussion
            </div>}
            {step==="notes" &&<div className={"text--grey text--center"}>
                Discutez en interne. Ni l'équipe de Black-belt ni le candidat n'auront accès à ces notes.
            </div>}
    
            <Spaces n={0}/>

            {step==="tchat" &&<div>
            <CPTchat {...props}/>
            </div>}
            {step==="answers" &&<div>
             <CPCandidacy {...props}/>
            </div>}
            {step==="notes" &&<div>
                <CPNotes {...props}/>
            </div>}
        </section>
    </>);
}