/**
 * All the routes here are generated in the Routing component
 * public are the routes that don't need authentication to access
 * private are routes that needs authentication to access
 * shared are routes that should always be available to anyone (example: a callback sent by email should point here)
 * Public and private routes MUST have a route pointing to the path "/", but right now the landing pages are not taken from here, should be refactored
 */

import {LandingPage} from "../components/publicSpacePages/LandingPage"
import {FunctionComponent} from "react";
import CandidatePage from "../components/publicSpacePages/CandidatePage";
import ClientPage from "../components/publicSpacePages/ClientPage";
import OnboardingPage from "../components/publicSpacePages/OnboardingPage";
import FlashCardDemo from "../components/publicSpacePages/FlashCardDemo";

export {routes};

export interface IRoute {
  path: string;
  page: string;
  component: FunctionComponent<any>;
  exact: boolean;
}

interface IRoutes {
  public: IRoute[];
  shared: IRoute[];
  private: IRoute[];
}

const routes: IRoutes = {
  public: [],
  shared: [],
  private: [],
};

// --------------------------- Public routes
routes.public.push({exact: true, path: '/', page: 'LandingPage', component: LandingPage});

routes.public.push({exact: true, path: '/entreprise/:token', page: 'ClientPage', component: ClientPage});
routes.public.push({exact: true, path: '/candidat/:token', page: 'CandidatePage', component: CandidatePage});
routes.public.push({exact: true, path: '/onboarding/:token', page: 'OnboardingPage', component: OnboardingPage});
routes.public.push({exact: true, path: '/flashcard/:id', page: 'FlashCardDemo', component: FlashCardDemo});


// routes.public.push({exact: true, path: '/action/:type/:token', page: 'CandidatePage', component: LandingPage});



