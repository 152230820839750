import React, {useEffect, useState} from "react";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import NavBar from "../sharedComponents/components/NavBar";
import {devLog} from "../../utils/devLog/devLog";
import axios from "axios";
import Spaces from "../sharedComponents/components/Spaces";
import CPHeader from "./componentsCandidatePage/CPHeader";
import CPMatching from "./componentsCandidatePage/CPMatching";
import CPInformations from "./componentsCandidatePage/CPInformations";
import CPEvaluation from "./componentsCandidatePage/CPEvaluation";
import CPVideo from "./componentsCandidatePage/CPVideo";
import CPTchatAndAnswers from "./componentsCandidatePage/CPTchatAndAnswers";
import CPFeedbackInterview from "./componentsCandidatePage/CPFeedbackInterview";
import CPSteps from "./componentsCandidatePage/CPSteps";
import CPFeedbackInterview2 from "./componentsCandidatePage/CPFeedbackInterview2";

export default (props: any) => {
  
  const token: string = props.match.params.token || "";
  const [candidates, setCandidates]: any = useState([]);
  const [index, setIndex]: any = useState(0);
  const [mission, setMission]: any = useState({});
  const [business, setBusiness]: any = useState({});
  const [loading, setLoading] = useState<"loading" | "error" | "forbidden" | "loaded">('loading');
  const [status, setStatus]: any = useState({});
  console.log("candidates", candidates);
  console.log("business", business);
  
  /**
   * Timer
   */
  useEffect(() => {
    try {
      const timer = setTimeout(async() => {
        const {status, data} =
          (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/candidate/increment/views`,
            {
              token: token,
            }
            , {
              withCredentials: true,
            })).data;
        if(status === "ok") {
        }
      }, 6_000);
      return () => clearInterval(timer);
      
    } catch(error) {
      console.log(error);
    }
  }, [index]);
  
  useEffect(() => {
    (async function() {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/shared/candidate`,
          {
            headers: {token: token},
            withCredentials: true
          })).data;
        setStatus(status);
        setCandidates(data.candidates);
        setBusiness(data.business);
        setMission(data.mission);
        setLoading('loaded');
        
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  if(status === "noCandidate" || status === "noToken" || status === "noSharedData" || status === "noMission" || status === "noClient") {
    return (<PageLayout pageStatus={"loaded"}>
      <Spaces n={8}/>
      <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
      <Spaces n={8}/>
    </PageLayout>);
  }
  
  return (<PageLayout pageStatus={loading}>
    
    <NavBar page={"ClientPage"}
            leftText={mission?.positionWanted}
            rightText={
              <div className={"space-flex--center text--bold text--white"}>
                <button className={"candidate-page__button--switch candidate-page__button--previous"} onClick={() => {
                  if(index === 0) {setIndex(candidates.length - 1)} else {setIndex((ps: number) => ps -= 1);}
                }}>
                  <img className={"img-type svg--white"}
                       src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                       alt={"toggle"}/>
                </button>
                <div>Candidat(e) {index + 1} sur {candidates.length}</div>
                <button className={"candidate-page__button--switch"} onClick={() => {
                  if(index === (candidates.length - 1)) {setIndex(0)} else {setIndex((ps: number) => ps += 1);}
                }}>
                  <img className={"img-type svg--white"}
                       src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                       alt={"toggle"}/>
                </button>
              </div>
            }
            mainPageToken={business.businessToken}/>
    
    <Spaces n={3}/>
    <div className={"candidate-page__top"}>
      
      <div className={"hide-on-computers"}>
        
        <div className={"column"}>
          <div><span
            className={"hide-on-tablets-and-smartphones"}>Ce(tte) candidat(e) est proposé(e) pour le poste de: </span>
            <span className={"text--bold margin5px"}> {mission?.positionWanted} </span>
            {mission?.contract && <span className={"text--bold margin5px"}> | {mission?.contract.toUpperCase()} </span>}
            {mission?.city && <span className={"text--bold margin5px"}> | {mission?.city} </span>}</div>
          
          {/*<div className={"text--grey"}> Responsable du recrutement : {mission?.blackBeltManager}</div>*/}
        </div>
        
        <div className={"space-flex--center text--bold"}>
          <button className={"candidate-page__button--switch candidate-page__button--previous"} onClick={() => {
            if(index === 0) {setIndex(candidates.length - 1)} else {setIndex((ps: number) => ps -= 1);}
          }}>
            <img className={""}
                 src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                 alt={"toggle"}/>
          </button>
          <div>Vous avez {candidates.length} profil(s)</div>
          <button className={"candidate-page__button--switch"} onClick={() => {
            if(index === (candidates.length - 1)) {setIndex(0)} else {setIndex((ps: number) => ps += 1);}
          }}>
            <img className={"img-type"}
                 src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                 alt={"toggle"}/>
          </button>
        </div>
      </div>
    
    </div>

    <CPSteps candidate={candidates[index]}/>

    <Spaces n={2}/>
    
    <div className={"candidate-page__container"}>
      <div className={"candidate-page__left"}>
        <CPHeader candidate={candidates[index]} token={token}/>
        <CPMatching candidate={candidates[index]}/>
        {candidates[index]?.blackBeltFeedbackInterview && <CPFeedbackInterview candidate={candidates[index]}/>}
        {candidates[index]?.interview2 && <CPFeedbackInterview2 candidate={candidates[index]}/>}
        <CPInformations candidate={candidates[index]}/>
        <CPEvaluation candidate={candidates[index]}/>
      </div>
      
      <div className={"candidate-page__right"}>
        <CPVideo candidate={candidates[index]}/>
        <CPTchatAndAnswers candidate={candidates[index]} business={business} token={token}/>
      </div>
    </div>
    
    <Spaces n={3}/>
  
  </PageLayout>);
}