import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import FCDNav from "../FlashCardDemo/FCDNav";
import FCDStations from "../FlashCardDemo/FCDStations";
import Spaces from "../sharedComponents/components/Spaces";
import CPHeader from "./componentsCandidatePage/CPHeader";
import CPMatching from "./componentsCandidatePage/CPMatching";
import CPFeedbackInterview from "./componentsCandidatePage/CPFeedbackInterview";
import CPInformations from "./componentsCandidatePage/CPInformations";
import CPEvaluation from "./componentsCandidatePage/CPEvaluation";
import CPVideo from "./componentsCandidatePage/CPVideo";
import CPTchatAndAnswers from "./componentsCandidatePage/CPTchatAndAnswers";
import FCDHeader from "../FlashCardDemo/FCDHeader";
import FCDMatching from "../FlashCardDemo/FCDMatching";
import FCDContact from "../FlashCardDemo/FCDContact";
import FCDRefs from "../FlashCardDemo/FCDRefs";
const qs = require("qs");

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  const params = new URLSearchParams(window.location.search);
  const job = params.get('j') ?? "";
  const corporation = params.get('c') ?? "";
  const id = props.match.params.id;
  
  const [pageStatus, setPageStatus] = useState<"loading" | "loaded" | "error">("loading");
  const [candidate, setCandidate]: any = useState({});
  
  /**
   * Get candidate
   */
  useEffect(() => {
    (async() => {
      try {
        const answer = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/flashcard/demo`, {
          headers: {
            id: id
          },
          withCredentials: true,
        })).data;
        
        if(answer.status !== "ok") {
          return setPageStatus('error');
        }
  
        setCandidate(answer.data);
        setPageStatus('loaded');
        
      } catch(error) {
        setPageStatus('error');
      }
    })();
  }, []);
  
  console.log("=========================", candidate);
  
  return (<PageLayout pageStatus={pageStatus}>
    
    <FCDNav job={job} corporation={corporation} id={id} />
    
    <Spaces n={3}/>
    
    <div className={"hide-on-smartphones"}>
      <FCDStations corporation={corporation} />
    </div>
  
    <Spaces n={2}/>
    <div className={"candidate-page__container"}>
      <div className={"candidate-page__left"}>
        <FCDHeader candidate={candidate} job={job} corporation={corporation} id={id}/>
        <FCDMatching candidate={candidate}/>
        
        {candidate?.blackBeltFeedbackInterview && <CPFeedbackInterview candidate={candidate}/>}
        <CPInformations candidate={candidate}/>
        <FCDRefs job={job} corporation={corporation} id={id} candidate={candidate}/>
      </div>
    
      <div className={"candidate-page__right"}>
        <CPVideo candidate={candidate}/>
        <FCDContact candidate={candidate} job={job} corporation={corporation} id={id} />
      </div>
    </div>
    
    <Spaces n={2} />
  
  </PageLayout>);
}