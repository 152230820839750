import React from "react";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default (props: { candidate: any, }) => {

    return (<>
        <section className={"flash-card-container"}>
            <h1 className={"candidate-page__title text--blue"}>Matching pour ce poste:</h1>
            <div className={"space-flex--up"}>
                <div className={"column margin30px"}>
                    <div style={{ width: 50, height: 50 }}>
                    <CircularProgressbar
                        value={props.candidate?.blackBeltCulture*20}
                        text={`${props.candidate?.blackBeltCulture}/5`}
                        styles={buildStyles({
                            textSize: '30px',
                            pathColor: `#3898ec`,
                            textColor: `#3898ec`,})}/>
                    </div>
                    <div> Culture fit </div>
                </div>
                <div className={"column margin30px"}>
                    <div style={{ width: 50, height: 50 }}>
                        <CircularProgressbar
                            value={props.candidate?.blackBeltHardSkills}
                            text={`${props.candidate?.blackBeltHardSkills}%`}
                            styles={buildStyles({
                                textSize: '30px',
                                pathColor: `#2ec43d`,
                                textColor: `#2ec43d`,})}/>
                    </div>
                    <div> Competences </div>
                </div>
                <div className={"column margin30px"}>
                    <div style={{ width: 50, height: 50 }}>
                        <CircularProgressbar
                            value={props.candidate?.blackBeltSoftSkills*20}
                            text={`${props.candidate?.blackBeltSoftSkills}/5`}
                            styles={buildStyles({
                                textSize: '30px',
                                pathColor: `#ec8038`,
                                textColor: `#ec8038`,})}/>
                    </div>
                    <div> Soft Skills </div>
                </div>
            </div>
        </section>
    </>);
}