import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default(props: any) => {

    return(<>
        <section >
            <div>
                <div className={"candidate-page__answers"}>
                    <p className={"candidate-page__title"}>
                        Réponses du candidat
                    </p>

                    <Spaces n={0}/>

                    <div className={"text--center text--grey text--minified2x"}>
                        Une réponse valorisée apparait en bleu. Une réponse non valorisée apparait en grise. Une réponse éliminatoire
                        apparait en noir.
                    </div>

                    <Spaces n={1}/>

                    {props.candidate.answers?.filter((item: any) => (['freeText', 'singleChoice', 'multipleChoice'].includes(item.question?.type))).map((item: any, key: any) => (
                        <article key={key} className={`results-v2__answer`}>
                            <div className={"text--minified3x"}>Temps passé sur la
                                question: {item.timeSpent} seconde{item.timeSpent !== 1 ? "s" : ""}</div>
                            <div className={"results-v2__answer__question"}>({key + 1}) {item.question?.question}</div>
                            <CandidateAnswer item={item} index={key}/>
                        </article>))}

                    <Spaces n={1}/>

                </div>
            </div>
        </section>
    </>);

}



function CandidateAnswer(props: any) {
    switch(props.item?.question?.type) {
        case 'freeText':
            return (<p className={"text--center"}>{props.item.answer}</p>);
        case 'singleChoice':
            switch(props.item?.question?.blocking) {
                case 'true':
                    return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item?.answer === key && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer === key && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}`}>
                        {item2.answer}
                    </p>))}</>);
                case 'false':
                    return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item?.answer === key && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer === key && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}`}>
                        {item2.answer} (importance: {item2.answerData === "0" ? "nulle" : ""} {item2.answerData === "2" ? "faible" : ""} {item2.answerData === "5" ? "forte" : ""})
                    </p>))}</>);
                default:
                    return (<>Err</>);
            }
        case 'multipleChoice':
            switch(props.item?.question?.blocking) {
                case 'true':
                    return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
                        return <p className={`candidate-answers__answer
            ${(props.item?.answer?.includes(key) && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer?.includes(key) && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}
            `}>
                            {item2.answer}
                        </p>
                    })}</>);
                case 'false':
                    return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
                        return <p className={`candidate-answers__answer
            ${(props.item?.answer?.includes(key) && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer?.includes(key) && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}
            `}>
                            {item2.answer}
                        </p>
                    })}</>);
                default:
                    return (<>Err</>);
            }
    }
    return (<>
    </>);
}
