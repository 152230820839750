import React, {useContext, useEffect, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import {Link} from "react-router-dom";
import axios from "axios";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
  const token = appContext.appPanels.window.windowOptions.data?.token;
  
  const [windowStatus, setWindowStatus] = useState<"pending" | "ok">("pending");
  
  const [averageTimeToSend, setAverageTimeToSend] = useState(0);
  const [averageTimeToConfirm, setAverageTimeToConfirm] = useState(0);
  const [averageTimeToHiring, setAverageTimeToHiring] = useState(0);
  
  useEffect(() => {
    (async() => {
      try {
        const {
          status,
          data
        } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/stats`,
          {
            headers: {token: token},
            withCredentials: true
          })).data;
        setWindowStatus("ok");
        setAverageTimeToSend(data.averageTimeToSend);
        setAverageTimeToConfirm(data.averageTimeToConfirm);
        setAverageTimeToHiring(data.averageTimeToHiring);
      } catch(error) {
        console.log(error);
      }
    })();
  }, []);
  
  return (<WindowLayout size={"big"} status={windowStatus} windowTitle={"Statistiques pour cette entreprise"}>
    <div className={"window-stats-mission"}>
      <div className={"window-stats-mission-item"}>
        <div className={"window-stats-mission-item--title"}>Durée moy. de proposition</div>
        <div className={"window-stats-mission-item--date"}>{averageTimeToSend ?? "--"} jours</div>
      </div>
      <div className={"window-stats-mission-item"}>
        <div className={"window-stats-mission-item--title"}>Durée moy. de validation</div>
        <div className={"window-stats-mission-item--date"}>{averageTimeToConfirm ?? "--"} jours</div>
      </div>
      <div className={"window-stats-mission-item"}>
        <div className={"window-stats-mission-item--title"}>Durée pour pourvoir un poste</div>
        <div className={"window-stats-mission-item--date"}>{averageTimeToHiring ?? "--"} jours</div>
      </div>
    </div>
  </WindowLayout>);
}

