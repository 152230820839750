import React, {useContext} from "react";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {initialSession} from "../componentsApp/state/initialSession";

export {LandingPage};

const LandingPage = (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (<>
    
    Landing page
  
  </>);
  
};

