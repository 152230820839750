import React, {useContext, useState} from "react";
import WindowLayout from "../../layouts/WindowLayout";
import Spaces from "../Spaces";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import axios from "axios";

export default (props: any) => {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  const [windowStatus, setWindowStatus] = useState<"ok" | "pending" | "error">("ok");
  const [success, setSuccess] = useState<boolean>(false);
  
  const [gdpr, setGdpr] = useState(false);
  
  const action = appContext.appPanels?.window?.windowOptions?.data?.action;
  
  const [formData, setFormData] = useState<any>({
    corporation: appContext.appPanels?.window?.windowOptions?.data?.corporation ?? ""
  });
  
  function updateFormData(keyValue: any) {
    setFormData((ps: any) => ({
      ...ps,
      ...keyValue
    }));
  }
  
  async function contact() {
    try {
      setWindowStatus("pending");
      
      const answer = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/flashcard/demo/contact`, {
        corporation: appContext.appPanels?.window?.windowOptions?.data?.corporation,
        job: appContext.appPanels?.window?.windowOptions?.data?.job,
        id: appContext.appPanels?.window?.windowOptions?.data?.id,
        fullName: formData.fullName,
        position: formData.position,
        phone: formData.phone
      }, {
        withCredentials: true,
      })).data;
      
      if(answer.status !== "ok") {
        return setWindowStatus("error");
      }
      
      setWindowStatus("ok");
      setSuccess(true);
      
    } catch(error) {
      console.log(error);
      setWindowStatus("error");
    }
  }
  
  const windowTitle = (() => {
    switch(action) {
      case "cv":
        return `Accéder au CV de ${(appContext.appPanels?.window?.windowOptions?.data?.candidate?.firstName ?? [])[0] ?? "N"}.`;
  
      case "linkedin":
        return `Accéder au profil LinkedIn de ${(appContext.appPanels?.window?.windowOptions?.data?.candidate?.firstName ?? [])[0] ?? "N"}.`;
      
      case "refs":
        return `Accéder aux références de ${(appContext.appPanels?.window?.windowOptions?.data?.candidate?.firstName ?? [])[0] ?? "N"}.`;
  
      case "share":
        return "Créer votre compte Black-belt"
      
      default:
        return `Prenons contact !`
    }
  })()
  
  return (<WindowLayout size={"small"} status={windowStatus} windowTitle={windowTitle}>
    
    {
      success && <div className={"text--center"}>
        Nous avons bien enregistré votre demande, un Talent Acquisition Specialist vous contactera sous peu.
        <br/><br/>
        Vous pouvez fermer cette fenêtre !
      </div>
    }
    {
      !success && <div>
        <div className={"text--grey text--center text--small"}>
          {action === "cv" && <>Indiquez vos coordonnées afin de créer votre compte Black Belt et accéder à tous nos tops candidat(e)s</>}
          {action === "linkedin" && <>Indiquez vos coordonnées afin de créer votre compte Black Belt et accéder à tous nos tops candidat(e)s</>}
          {action === "share" && <>Afin de partager les profils avec vos collègues, vous devez créer un compte Black Belt.</>}
          {action === "refs" && <>Indiquez vos coordonnées afin de créer votre compte Black Belt et accéder à tous nos tops candidat(e)s</>}
          {action !== "cv" && action !== "linkedin" && action !== "share" && action !== "refs" && <>Contactez - nous
            pour rencontrer nos candidats !</>}
        </div>
        <Spaces n={0}/>
        
        {/*///////////////////////////////////////////////////////////////////////////////////////////:*/}

        <div className={"text--center"}>
          <div className={"input3"}>
            <label className={"input3__label"}>
              Société:
            </label>
            <input className={"input3__input text--small"} type={"text"} placeholder={"Votre société"}
                   value={formData.corporation ?? ""} onChange={(e) => updateFormData({corporation: e.target.value})}/>
          </div>

          <div className={"input3"}>
            <label className={"input3__label"}>
              Prénom & Nom:
            </label>
            <input className={"input3__input text--small"} type={"text"} placeholder={"Votre prénom et nom"}
                   value={formData.fullName ?? ""} onChange={(e) => updateFormData({fullName: e.target.value})}/>
          </div>

          <div className={`input3 ${formData.phone ? "" : "input3--red"}`}>
            <label className={`input3__label`}>
              Téléphone:
            </label>
            <input className={`input3__input text--small`}
                   type={"text"}
                   onChange={(e) => updateFormData({phone: e.target.value})}
                   placeholder={"Votre téléphone"} value={formData.phone ?? ""}/>
          </div>
          
          <Spaces n={0}/>
          <div className={"text--grey text--small form_gdpr"}>
            <label >
            <input className={"form_checkbox"} type={"checkbox"} checked={gdpr} onChange={() => setGdpr((ps: boolean) => !ps)}/>
            <span className={""}>En soumettant ce formulaire j'accepte que les informations saisies soient utilisées dans le cadre de la mise en relation avec le/la candidat(e).</span>
            </label>
          </div>
          <Spaces n={0}/>
          <button className={"button--linkedin"} disabled={!formData.phone || !gdpr}
                  title={"Un T.A.S. vous contactera sous peu !"}
                  onClick={() => {
                    contact()
                  }}>
            {action === "cv" && <>Accéder au CV</>}
            {action === "linkedin" && <>Accéder au profil</>}
            {action === "share" && <>Je crée mon compte</>}
            {action === "refs" && <>Accéder aux références</>}
            {action !== "cv" && action !== "linkedin" && action !== "share" && action !== "refs" && <>Prenons contact !</>}
          </button>
        </div>
        
      </div>
    }
  </WindowLayout>);
}