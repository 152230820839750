import React, {useContext} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import {format} from "date-fns";

export function SingleStep(props: {
  date: any;
  title: string;
  color: string;
}) {
  return(<div className={"candidate-page__step"}>
    {/*<div className={`candidate-page__step-circle ${props.color === "green" ?"candidate-page__step-green-circle":""} ${props.color==="red" ? "candidate-page__step-red-circle" : ""}`}> </div>*/}
    <div className={`candidate-page__step-circle candidate-page__step-circle1 ${props.color === "green" ? "circle--green" : ""} ${props.color === "red" ? "circle--red" : ""}`}>
      <div className={`candidate-page__step-circle candidate-page__step-circle2 circle--white`}>
        <div className={`candidate-page__step-circle candidate-page__step-circle3 ${props.color === "green" ? "circle--green" : ""} ${props.color === "red" ? "circle--red" : ""}`}>
        
        </div>
      </div>
    </div>
    <div className={"candidate-page__step-title"}>{props.title}</div>
    <div className={"candidate-page__step-date"}>
      {props.date ? format(new Date(props.date), "dd-MM-yyyy") : ""}
    </div>
  </div>)
}

export default (props: { candidate: any,}) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    return (<>
        <section className={"flash-card-steps-container hide-on-smartphones"}>
          <div className={"candidate-page__steps"}>
            <SingleStep date={props.candidate.missionDateOfCreation} title={"Création du poste"} color={props.candidate.missionDateOfCreation ? "green" : "grey"} />
            {/*<SingleStep date={props.candidate.candidacyDateOfCreation} title={"Réception candidature"} color={props.candidate.candidacyDateOfCreation ? "green" : "grey"} />*/}
            <SingleStep date={props.candidate.sentToClient} title={"Transmission candidature"} color={props.candidate.sentToClient || props.candidate.blackBeltKeep ? "green" : "grey"} />
            <SingleStep date={props.candidate.clientAnswer} title={"Validation ou rejet"} color={!props.candidate.blackBeltKeep ? "grey" : (props.candidate.blackBeltKeep === "yes" ? "green" : "red")} />
            <SingleStep date={props.candidate.blackBeltInterview} title={"Entretien Black-belt"} color={props.candidate.blackBeltInterview ? "green" : "grey"} />
            <SingleStep date={props.candidate.clientInterview} title={"Entretien client"} color={props.candidate.clientInterview ? "green" : "grey"} />
            <SingleStep date={props.candidate.jobOffer} title={"Promesse d'embauche"} color={props.candidate.jobOffer ? "green" : "grey"} />
            <SingleStep date={props.candidate.onboarding} title={"Onboarding"} color={props.candidate.onboarding ? "green" : "grey"} />
          </div>
        </section>
    </>);
}