import React, {useContext} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowShareCandidate from "./WindowShareCandidate";
import WindowCandidatesOfMission from "./WindowCandidatesOfMission";
import WindowBusinessBlackBeltManagers from "./WindowBusinessBlackBeltManagers";
import WindowStatsOfMission from "./WindowStatsOfMission";
import WindowBusinessBlackBeltStats from "./WindowBusinessBlackBeltStats";
import WindowBusinessBlackBeltOnboarding from "./WindowBusinessBlackBeltOnboarding";
import WindowForm from "./WindowForm";
import WindowCalendly from "./WindowCalendly";

export {Window};

const Window = (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    switch(appContext.appPanels?.window?.windowOptions?.type) {

        case "shareCandidate":
            return (<WindowShareCandidate/>);

        case "candidatesOfMission":
            return (<WindowCandidatesOfMission/>);

        case "statsOfMission":
            return (<WindowStatsOfMission/>);

        case "businessBlackBeltManagers":
            return (<WindowBusinessBlackBeltManagers/>);

        case "businessBlackBeltStats":
            return (<WindowBusinessBlackBeltStats/>);

        case "businessBlackBeltOnboarding":
            return (<WindowBusinessBlackBeltOnboarding/>);
    
        case "form":
            return (<WindowForm/>);
    
        case "calendly":
            return (<WindowCalendly/>);
            
        default:
            return (<></>);
    }

}

